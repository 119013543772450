import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Step from '@/components/steps/Step'
import { StepDefinition } from '@/models'
import moment from 'moment'
import { localize } from 'vee-validate'

@Component
export class Form extends Vue {
  //#region [Property]
  @Prop({ type: String, required: true }) public readonly lang!: string
  //#endregion

  //#region [Data]
  public stepDefinitions!: StepDefinition[]
  public currentStep: number = 0
  public lastStepSeen: number = 0
  public loading: string | null = null

  public canContinue: boolean = true
  public stepComponent: Step | null = null
  public next: boolean | null = null
  public status: 1 | 0 | -1 | undefined = undefined
  public stepToGoAfterValidation: number = 0
  public stepsToValidate: number[] = []
  //#endregion

  //#region [Computed]
  public get lastStep(): number {
    return Math.max.apply(Math, this.stepDefinitions.map((o) => o.step))
  }

  public get firstStep(): number {
    return Math.min.apply(Math, this.stepDefinitions.map((o) => o.step))
  }

  public get canNavigate(): boolean {
    return this.lastStepSeen < this.lastStep && !this.loading
  }

  //#endregion

  //#region [Watch]
  @Watch('lang')
  public onLangChanged(value: string) {
    this.$i18n.locale = value
    localize(value)
    this.$store.commit('SET_LANGUAGE', value)
    moment.locale(value)
  }

  @Watch('currentStep')
  public onCurrentStepChanged(currentStep: number) {
    if (this.lastStepSeen < currentStep) {
      this.lastStepSeen = currentStep
    }

    /* tslint:disable-next-line:no-string-literal */
    if (!(this.$route.query['step'] && this.$route.query['step'] === currentStep.toString())) {
      this.$router.replace({ path: this.$route.path, query: { step: currentStep.toString() } })
    }
    this.scrollTop()
  }

  //#endregion

  //#region [Method]
  public mounted() {
    /* tslint:disable-next-line:no-string-literal */
    if (!(this.$route.query['step'] && this.$route.query['step'] === this.currentStep.toString())) {
      this.$router.replace({ path: this.$route.path, query: { step: this.currentStep.toString() } })
    }
  }

  /**
   * Gets the step component for the step number
   * Virtual method
   */
  public getStepComponent(step: number): Step | null {
    return null
  }

  public async goToStep(step: number) {
    if (!this.canNavigate || step > this.lastStepSeen || step === this.currentStep) {
      return
    }

    this.loading = 'goto'
    const stepComponent = this.getStepComponent(this.currentStep)

    const next = step > this.currentStep
    if (!stepComponent) {
      this.currentStep = step
      this.loading = null
      return
    }

    const status = await stepComponent.change(next)
    switch (status) {
      case 1:
        this.currentStep = step
        break
      case 0:
        this.lastStepSeen = this.currentStep
        if (!next) {
          this.currentStep = step
        }
        break
      case -1:
        this.lastStepSeen = this.currentStep
        break
    }

    this.loading = null
  }

  public async nextStep() {
    this.canContinue = true
    this.currentStep++
  }

  public async previousStep(valid: boolean) {
    if (!valid) {
      this.lastStepSeen = this.currentStep
    }

    this.canContinue = true
    this.currentStep--
  }

  public async tryNextStep() {
    this.loading = 'next'
    const step = this.getStepComponent(this.currentStep)
    if (step != null) {
      step.next()
        .finally(() => this.loading = null)
    }
  }

  public async tryPreviousStep() {
    this.loading = 'previous'
    const step = this.getStepComponent(this.currentStep)
    if (step != null) {
      step.previous()
        .finally(() => this.loading = null)
    }
  }

  public ensureLastStep(step: number, isValid: boolean) {
    if (!isValid && this.lastStepSeen > step) {
      this.lastStepSeen = step
      this.stepToGoAfterValidation = step
    }
  }

  public scrollTop() {
    document.getElementsByClassName('lib__header')[0].scrollIntoView()
  }

  public async goToStepWithValidation(stepToGo: number): Promise<void> {
    if (this.currentStep === stepToGo) {
      return
    }
    if (this.stepsToValidate.some((x: number) => x === this.currentStep)) {
      document.addEventListener('afterEnsured', this.afterEnsured)
      this.stepToGoAfterValidation = stepToGo
      this.stepComponent = this.getStepComponent(this.currentStep)
      this.next = stepToGo > this.currentStep
      this.status = await this.stepComponent?.change(this.next)

      setTimeout(() => {
        document.dispatchEvent(new CustomEvent('afterEnsured'))
      }, 300)
    } else {
      await this.goToStep(stepToGo)
    }
  }

  public async afterEnsured(): Promise<void> {
    switch (this.status) {
      case 1:
        const oldStep = this.currentStep
        if (this.lastStepSeen >= this.stepToGoAfterValidation) {
          this.currentStep = this.stepToGoAfterValidation
        } else if (this.currentStep > this.stepToGoAfterValidation) {
          this.currentStep = this.currentStep - 1
        } else {
          this.currentStep = this.currentStep + 1
        }
        if (oldStep !== this.currentStep) {
          this.canContinue = true
        }
        break
      case 0:
        this.lastStepSeen = this.currentStep
        if (!this.next) {
          this.currentStep = this.stepToGoAfterValidation
          this.canContinue = true
        }
        break
      case -1:
        this.lastStepSeen = this.currentStep
        break
    }
    this.loading = null
    document.removeEventListener('afterEnsured', this.afterEnsured)
  }

  protected CheckMaintenance(formKey: string) {
    const me = this;
    me.$api.get(`maintenance/${formKey}`)
      .then((response) => {
        if (response.data.status === 'active') {
          me.$router.push({ path: '/maintenance' })
        }
      });
  }

  //#endregion
}

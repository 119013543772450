// A PRCQuestion will display an array of PRCChoices to choose from
export interface PRCQuestion {
  label: string,
  choices: PRCChoice[],
}

// A PRCChoice can lead to another question, or to a PRCResult
export interface PRCChoice {
  label: string,
  // selected?: boolean,
  next: PRCQuestion | PRCResult
}

// A PRCResult means the end of the decision tree
export interface PRCResult {
  label: string,
}

export function isPRCQuestion(o: PRCQuestion | PRCChoice | PRCResult): o is PRCQuestion {
  return Array.isArray((o as PRCQuestion).choices)
}

export function isPRCChoice(o: PRCQuestion | PRCChoice | PRCResult): o is PRCChoice {
  return typeof (o as PRCChoice).next === 'object'
}

export function isPRCResult(o: PRCQuestion | PRCChoice | PRCResult): o is PRCResult {
  return !isPRCQuestion(o) && !isPRCChoice(o)
}

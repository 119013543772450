















































import { Form } from '@/views/forms/Form'
import { Component, Watch } from 'vue-property-decorator'
import { isPRCQuestion, PRCChoice, PRCQuestion, PRCResult } from '@/models/prc'
import { localize } from 'vee-validate'
import moment from 'moment'

@Component({
  name: 'prc',
  components: {},
})
export default class PRC extends Form {
  //#region [Property]
  //#endregion

  //#region [Data]
  public tree: Array<PRCQuestion | PRCResult> = [this.getQuestions()]
  public selected: PRCChoice[] = []
  public isPRCQuestion = isPRCQuestion
  //#endregion

  //#region [Watch]
  @Watch('lang')
  public onLangChanged(value: string) {
    this.$i18n.locale = value
    localize(value)
    this.$store.commit('SET_LANGUAGE', value)
    moment.locale(value)

    // Re-fetch the questions to force the locale update
    this.tree = [this.getQuestions()]
  }

  //#endregion

  //#region [Method]
  public beforeCreate() {
    document.title = 'Formulaire PRC'
  }

  public mounted() {
    this.onLangChanged(this.lang)
  }

  public getQuestions() {
    const t = (s: string) => this.$t(s).toString()

    // Level 4
    const level4A: PRCResult = {
      label: t('prc.level4A'),
    }
    const level4B: PRCResult = {
      label: t('prc.level4B'),
    }
    const level4C: PRCResult = {
      label: t('prc.level4C'),
    }
    const level4D: PRCResult = {
      label: t('prc.level4D'),
    }
    const level4E: PRCResult = {
      label: t('prc.level4E'),
    }
    const level4F: PRCResult = {
      label: t('prc.level4F'),
    }

    // Level 3
    const level3Yes1: PRCChoice = {
      label: t('prc.level3Yes1'),
      next: level4A,
    }
    const level3Yes2: PRCChoice = {
      label: t('prc.level3Yes2'),
      next: level4B,
    }
    const level3Yes3: PRCChoice = {
      label: t('prc.level3Yes3'),
      next: level4C,
    }
    const level3Yes4: PRCChoice = {
      label: t('prc.level3Yes4'),
      next: level4D,
    }
    const level3Yes5: PRCChoice = {
      label: t('prc.level3Yes5'),
      next: level4E,
    }
    const level3Yes6: PRCChoice = {
      label: t('prc.level3Yes6'),
      next: level4F,
    }
    const level3No: PRCResult = {
      label: t('prc.level3No'),
    }

    // Level 2.5
    const qcm: PRCQuestion = {
      label: t('prc.qcm'),
      choices: [level3Yes1, level3Yes2, level3Yes3, level3Yes4, level3Yes5, level3Yes6],
    }

    // Level 2
    const level2Yes: PRCResult = {
      label: t('prc.level2Yes'),
    }
    const level2No: PRCQuestion = {
      label: t('prc.level2No'),
      choices: [
        { label: t('prc.yes'), next: qcm },
        { label: t('prc.no'), next: level3No },
      ],
    }

    // level 1
    const level1Yes: PRCQuestion = {
      label: t('prc.level1Yes'),
      choices: [
        { label: t('prc.yes'), next: level2Yes },
        { label: t('prc.no'), next: level2No },
      ],
    }
    const level1No: PRCResult = {
      label: t('prc.level1No'),
    }

    // Start
    const firstQuestion: PRCQuestion = {
      label: t('prc.entryPoint'),
      choices: [
        { label: t('prc.yes'), next: level1Yes },
        { label: t('prc.no'), next: level1No },
      ],
    }

    return firstQuestion
  }

  public isSelected(choice: PRCChoice): boolean {
    return this.selected.includes(choice)
  }

  public deselect(choice: PRCChoice) {
    const pos = this.selected.indexOf(choice)
    this.selected = this.selected.slice(0, pos)
    this.tree.length = pos + 1
  }

  public select(choice: PRCChoice) {
    this.selected.push(choice)
    this.tree.push(choice.next)
  }

  public pushStep(question: PRCQuestion, choice: PRCChoice) {
    // Do nothing if we already selected this choice
    if (!choice.next || this.isSelected(choice)) {
      return
    }

    // Rollback if we already selected another choice for the same question
    const rollback = question.choices.find((q) => this.isSelected(q))
    if (rollback) {
      this.deselect(rollback)
    }

    this.select(choice)
    this.$nextTick(() => {
      const choices = this.$refs.choices as Element[]
      choices[choices.length - 1].scrollIntoView({ behavior: 'smooth' })
    })
  }

  //#endregion
}
